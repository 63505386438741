import { Button, Grid, Typography } from "@material-ui/core"
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { ConfirmarIcon } from "../../icons/confirmar-icon"
import { DesfazerIcon } from "../../icons"
import { useStyles } from "../dialog-processo-setor/dialog-processo-setor-styles"
import { FormConfigLayout } from "../../form/master/config-layout/form-config-layout"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ConfigLayoutFormModel } from "../../../../model/app/forms/master/config-layout-model"
import { DefaultFormRefs } from "../../form/utils"
import { KdsStorageKeys, useKdsStorage, useToastSaurus } from "../../../../services/app"
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools"
import { AppEventEnum } from "../../../../model/enums/enum-app-event"
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model"
import { isEmpty } from "lodash"
import { CircularLoading } from "../../utils"
import { useDialog } from "../../../../services/app/providers/dialog-provider"

interface Props {
    openned: boolean
}

export const DialogConfigLayoutSetores = ({ openned }: Props) => {
    const [carregando, setCarregando] = useState<boolean>(false)
    const { fecharConfigLayoutSetores } = useDialog()
    const classes = useStyles();
    const { setRegistro, getRegistro } = useKdsStorage()
    const { callEvent } = useEventTools()
    const {showToast} = useToastSaurus()
    const setoresStorage = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]
    const setores: ProcessoSetorModel[] = useMemo(() => isEmpty(setoresStorage) ? [] : setoresStorage, [setoresStorage])

    const formConfigRef = useRef<DefaultFormRefs<ConfigLayoutFormModel>>(null)
    const submitFormConfigLayout = useCallback((model: ConfigLayoutFormModel) => {
        setCarregando(true)
        if(+model.qtdSetores > setores.length ){
            showToast('error', 'Quantidade maior do que setores disponíveis')
            setCarregando(false)
            return
        }
        setRegistro(KdsStorageKeys.ConfiguracoesLayout, model, false);
        callEvent(AppEventEnum.LayoutAlterado, model)
        setCarregando(false)
        fecharConfigLayoutSetores()
    }, [callEvent, fecharConfigLayoutSetores, setRegistro, setores.length, showToast])

    useEffect(() => {
        setTimeout(() => {
            if (openned) {
                let configLayout = getRegistro(KdsStorageKeys.ConfiguracoesLayout, false) as ConfigLayoutFormModel
                if (isEmpty(configLayout)) {
                    const modelTamanhos = new ConfigLayoutFormModel(setores.length.toString(), '2')
                    setRegistro(KdsStorageKeys.ConfiguracoesLayout, modelTamanhos, false)
                    formConfigRef.current?.fillForm(modelTamanhos)
                    callEvent(AppEventEnum.LayoutAlterado, modelTamanhos)
                    return
                }
                formConfigRef.current?.fillForm(configLayout)
                callEvent(AppEventEnum.LayoutAlterado, configLayout)
            }
        }, 100)

    }, [callEvent, getRegistro, openned, setRegistro, setores.length])

    return (
        <DialogSaurus aberto={openned} tamanho="md">
            <Grid container spacing={2} justifyContent="center" className={classes.dialogContainer}>
                <Grid item xs={12} className={classes.titleStyle} >
                    <Typography className={classes.titlePrincipal}>Layout de Setores e Processos</Typography>
                    <Typography className={classes.subTittle}>
                        Informe a quantidade de setores e processos (na horizontal) que deseja exibir no KDS:
                    </Typography>
                </Grid>
                {carregando && (
                        <div style={{
                            height: 300,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <CircularLoading tipo="FULLSIZED" />
                        </div>
                    )}
                <Grid item xs={12}>
                    <FormConfigLayout
                        ref={formConfigRef}
                        onSubmit={submitFormConfigLayout}
                        showLoading={carregando}
                        loading={carregando} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        startIcon={<ConfirmarIcon tipo="BUTTON_PRIMARY" />}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => {
                            formConfigRef.current?.submitForm()
                        }}
                    >
                        <Typography
                            className={classes.titleContinuar}>Continuar</Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<DesfazerIcon tipo="BUTTON" />}
                        onClick={fecharConfigLayoutSetores}
                        size="large"
                        fullWidth
                    >
                        <Typography className={classes.titleVoltar}>Voltar</Typography>
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}