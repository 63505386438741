import { useStyles } from "./selecionar-setor-styles";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Button, Card, Typography } from "@material-ui/core";
import { AdicionarIcon } from "../../../components/icons";
import { CircularLoading } from "../../../components";
import { useGetProcessoProdutoSetor } from "../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { useDialog } from "../../../../services/app/providers/dialog-provider";
import { Navbar } from "../../../components/nav";

const SelecionarSetorPage = () => {
  const classes = useStyles();
  const { carregando } = useGetProcessoProdutoSetor()
  const { abrirSetores } = useDialog()

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <div className={classes.divRoot}>
        <Navbar titulo="Selecionar Setor" />
        <div className={classes.root}>
          <Card className={classes.card}>
            <Button style={{
              padding: '6px 8px',
              width: '100%',
              height: '100%'
            }} onClick={() => abrirSetores()}>
              <div>
                <AdicionarIcon />
                <Typography className={classes.label}>Selecionar Setor</Typography>
              </div>
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
}
export default SelecionarSetorPage;
