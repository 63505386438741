import { useCallback } from "react";
import { EnumMenu } from "../../../model";
import { ModulosMock } from "../../mocks/menu-mock";
import { KdsStorageKeys, useKdsStorage } from "../../../services/app";
import { EnumModulo } from "../../../model/enums/enum-modulo";

export const useGetMenu = () => {

  const { getRegistro } = useKdsStorage()

  const getMenu = useCallback((isMovAberta: boolean, modulos?: EnumMenu[]) => {

    const tipo = getRegistro(KdsStorageKeys.SelecaoModulo, false);

    let modulos_filtered = ModulosMock
      //FILTRO MODULOS QUE PEDIRAM PARA ESCONDER
      .filter(x => !modulos?.includes(x.codigoPai))
      //FILTRO MODULOS SEM PERMISSAO
      // .filter(x => { return validaModuloNasPermissoes(perfilAtual, x) })
      .filter(x => {
        if (x.codigo === EnumMenu.MONITORAMENTO && tipo.modulo === EnumModulo.KDS)
          return true;
        if (x.codigo === EnumMenu.CONFIGURARSETORES && tipo.modulo === EnumModulo.KDS)
          return true;
        if (x.codigo === EnumMenu.CONCLUIDOSCANCELADOS && tipo.modulo === EnumModulo.KDS)
          return true;
        if (x.codigo === EnumMenu.CONFIGURARLAYOUT && tipo.modulo === EnumModulo.KDS)
          return true;
        if (x.codigo === EnumMenu.PESQUISAAVANCADA && tipo.modulo === EnumModulo.KDS)
          return true;
        if (x.codigo === EnumMenu.ENTREGALOCAL && tipo.modulo === EnumModulo.EntregaLocal)
          return true;
        if (x.codigo === EnumMenu.ENTREGADELIVERY && tipo.modulo === EnumModulo.EntregaDelivery)
          return true;

        return false
      })


      .sort((firstEl, secondEl) => firstEl.posicao - secondEl.posicao);

    //TIRO OS PAIS SEM FILHOS


    return modulos_filtered;
  }, [getRegistro]);

  return { getMenu };
};
