import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        flex: '1',
        width: '100%',
        border: '2px solid #1F9CE4',
        height: '100%',
        display: 'flex',
        padding: '60px 150px',
        position: "relative",
        maxWidth: '1200px',
        boxShadow: '0px 0px 10px',
        maxHeight: '920px',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '20px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            border: '2px solid #1F9CE4',
            height: '100%',
            display: 'flex',
            padding: '59px 87px',
            maxWidth: '700px',
            boxShadow: '0px 0px 10px',
            maxHeight: '800px',
            alignItems: 'center',
            alignContent: 'center',
            borderRadius: '20px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF'
        },
        [theme.breakpoints.down("md")]: {
            width: '100%',
            border: '2px solid #1F9CE4',
            height: '100%',
            display: 'flex',
            padding: '45px 60px',
            maxWidth: '680px',
            boxShadow: '0px 0px 10px',
            maxHeight: '840px',
            alignItems: 'center',
            alignContent: 'center',
            borderRadius: '20px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF'
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            border: '2px solid #1F9CE4',
            height: '100%',
            display: 'flex',
            padding: '46px 28px',
            maxWidth: '680px',
            boxShadow: '0px 0px 10px',
            maxHeight: '840px',
            alignItems: 'center',
            alignContent: 'center',
            borderRadius: '20px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF'
        }
    },
    divSetor: {
        display: 'grid',
        flexDirection: 'column',
        width: '100%',
    },
    modal: {
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
    },
    subTittle: {
        padding: '0 0 30px 0',
        color: '#8D8D8D',
        fontWeight: 600,
        fontSize: '20px',
        [theme.breakpoints.down("md")]: {
            color: '#8D8D8D',
            padding: '10px 0 30px 0',
            fontSize: '15px',
            fontWeight: '600'
        }
    },
    titleVoltar: {
        fontWeight: 600,
        fontSize: '16px',
        [theme.breakpoints.down("md")]: {
            fontWeight: 600,
            fontSize: '15px',
        }
    },
    titlePrincipal: {
        color: '#1F9CE4',
        fontSize: '40px',
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
            color: '#1F9CE4',
            fontSize: '22px',
            fontWeight: 700,
        }
    },
    botaoVoltar: {
        width: '100%'
    },
    titleContinuar: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#FDFDFD',
        [theme.breakpoints.down("md")]: {
            color: '#FDFDFD',
            fontSize: '16px',
            fontWeight: 700,
        }
    },
    gridDividir: {
        padding: '70px',
        width: '30px',
        height: '30px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.15);',
        borderRadius: '15px',
    },
    grid: {
        maxHeight: '450px',
        overflowX: 'auto',
        overflowY: 'auto',
        [theme.breakpoints.down("xl")]: {
            maxHeight: '450px',
        },
        [theme.breakpoints.down("lg")]: {
            maxHeight: '450px'
        },
        [theme.breakpoints.down("md")]: {
            maxHeight: '700px'
        }
    },
    titleGrid: {
        fontWeight: 700,
        fontSize: '40px',
        color: '#1F9CE4',
    },
    titleStyle: {
        display: 'flex',
        flexDirection: 'column',
    },
    setores: {
        marginTop: '20px',
        borderRadius: '10px!important',
        border: '1px solid #1F9CE4',
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    formControl: {
        width: "90%",
        marginTop: 10,
        marginLeft: 0,
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        fontWeight: 500,
        fontSize: '20px',
        color: '#A8A8A8',
        [theme.breakpoints.down("md")]: {
            color: '#A8A8A8',
            fontSize: '15px',
            fontWeight: '500'
        }
    },
    titleConfirmar: {
        boxShadow: '0 0 7px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        backgroundColor: '#1F9CE4',
        color: '#FDFDFD'
    },
    container: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        paddingBottom: '10px'
    },
    divVoltar: {
        flexDirection: 'column',
        display: 'flex',
    },
    dividerTitle: {
        backgroundColor: '#DADADA',
        marginLeft: '15px',
        width: "95%",
        height: '1px'
    },
    nadaParaExibir: {
        color: 'rgb(158 156 156)',
        padding: '12rem 0',
        fontSize: '20px',
        textAlign: 'center'
    },
    divider: {
        backgroundColor: '#EEEEEE',
        marginTop: 5,
        width: "90%",
        height: '1px'
    },
    dialogContainer:{
        boxSizing: 'border-box', 
        paddingBottom: '16px' 
    }
}))