import { Grid } from "@material-ui/core"
import { CardProcesso } from "../../../../../components/cards/card-processo/card-processo"
import { ProcessosListProps } from "./monitoramento-setores-processos-list-props"

export const MonitoramentoSetoresProcessosList = ({
    processos,
    produtosSetor,
    processosSelecionados,
    setAbrirQrCode,
    setProcessosSelecionados,
    getProcessoProdutoSetorWrapper,
    tamanhoDoComponenteDeProcessos,
    setores,
    tamanhoDoSetor,
    ...props
}: ProcessosListProps) => {

    return (
        <>
            {processos.sort((a, b) => a.processoProducao - b.processoProducao).map((processo, index, count) => {

                const produtosProcessos = produtosSetor.filter(produto => produto.processoProducaoAtual === processo.processoProducao)

                const processosqtd = processos?.length ?? 1;
                

                return (
                    <>
                        <Grid item xs={tamanhoDoComponenteDeProcessos}>
                            <CardProcesso
                                count={count}
                                isMonitoramento
                                pedidos={props.pedidos}
                                getProcessoProdutoSetorWrapper={getProcessoProdutoSetorWrapper}
                                processo={processo}
                                processosqtd={processosqtd}
                                produtosProcessos={produtosProcessos}
                                setAbrirQrCode={setAbrirQrCode}
                                processosSelecionados={processosSelecionados}
                                setProcessosSelecionados={setProcessosSelecionados}
                                setores={setores}
                                tamanhoDoSetor={tamanhoDoSetor}
                            />
                        </Grid>
                    </>
                )
            })}
        </>
    )
}