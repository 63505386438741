import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormConfigLayoutValidation = () => {
    const FormConfigLayoutValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                qtdSetores:Yup.string().required('Quantidade de Setores é obrigatório.'),
                qtdProcessos:Yup.string().required('Quantidade de Processos é obrigatório.'),
            })
        )
    }, [])
    return {
        FormConfigLayoutValidationYup,
    }
}
