import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divPedido: {
        padding: '0 10px 0 10px',
        opacity: 1,
        overflowY: 'auto',
        height: 'calc(100vh - 370px)',
        // margin: '10px'
    },
    divPedidoUnicoSetor: {
        padding: '0 10px 0 10px',
        opacity: 1,
        overflowY: 'auto',
        height: 'calc(100vh - 290px)',
    },
    divContainer: {
        height: '100%',
        padding: '16px',
        // margin: '15px 0 20px 0',
        background: '#F6F7F8',
        borderRadius: '8px',
        boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.15)',
        animationName: 'focus-in-contract',
        animationDuration: '1s',
        animationTimingFunction: 'ease-in',
        animationDelay: '1.2s',
        animationIterationCount: '1',
        animationDirection: 'normal',
        animationFillMode: 'none',
    },

    titleM: {
        color: '#808080',
        padding: '0 0 0 20px',
        fontSize: '21px',
        fontWeight: 600,
        alignContent: 'center',
        display: 'flex',
        [theme.breakpoints.down("xl")]: {
            color: '#808080',
            padding: '0px',
            fontSize: '21px',
            fontWeight: '600'
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: '8px',
            color: '#808080',
            fontWeight: 600,
            fontSize: '23px',
        },
    },
    description: {
        color: '#808080',
        padding: '0 0 0 20px',
        alignContent: 'center',
        display: 'flex',
    },
    title: {
        color: '#808080',
        display: 'inline-flex',
        padding: '40px 0 10px 10px',
        fontSize: '30px',
        fontWeight: 600,
        [theme.breakpoints.down("xl")]: {
            color: '#808080',
            display: 'inline-flex',
            fontWeight: 600,
            fontSize: '30px',
            padding: '40px 0 10px 10px',
        },
        [theme.breakpoints.down("md")]: {
            color: '#808080',
            display: 'inline-flex',
            fontWeight: 600,
            fontSize: '30px',
            padding: '40px 0 10px 10px',
        },
    },
    divider: {
        backgroundColor: '#BDBDBD',
        marginBottom: '10px',
        width: "100%",
        height: '1px',
    },
    dividerSetor: {
        width: '100%',
        height: '1px',
        backgroundColor: '#D9D9D9'
    },
    nadaParaExibir: {
        color: 'rgb(158 156 156)',
        padding: '6rem 0px 0rem 0',
        fontSize: '20px',
        textAlign: 'center'
    },
    loading: {
        position: 'fixed',
        right: 0,
        top: 60,
        '& > div': {
            width: 80,
            height: 80
        }
    },
    titlePesquisa: {
        marginBottom: 4
    }
}))