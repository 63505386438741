import { createStyles, makeStyles } from '@material-ui/styles';
import Divide from '../icons/icon-img/dividir.png'

export const DividirIcon = () => {
  const useStyles = makeStyles(() => 
    createStyles({
      img: {
        height: '28px',
        maxWidth: '35px',
      },
    })
  )
  const classes = useStyles();
  return (
    <>
      <img
      className={classes.img}
      src={Divide} 
      alt="Divisoria de setores" 
      />
    </>
  );
};
