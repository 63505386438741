import { createContext, useContext, useState } from "react"
import { DialogProcessoSetor } from "../../../../views/components/dialog/dialog-processo-setor/dialog-processo-setor";
import { DialogConfigLayoutSetores } from "../../../../views/components/dialog/dialog-config-layout-setores/dialog-config-layout-setores";

interface DialogContextValues {
   //dialog de setores
   fecharSetores: () => void,
   abrirSetores: () => void,

   //dialog de configurações de layout
   fecharConfigLayoutSetores: () => void,
   abrirConfigLayoutSetores: () => void

}

const DialogContext = createContext<DialogContextValues>({
   fecharSetores: () => true,
   abrirSetores: () => true,
   fecharConfigLayoutSetores: () => true,
   abrirConfigLayoutSetores: () => true,

})
export interface DialogProviderProps {
   children: React.ReactNode;
}

export const useDialog = () => useContext(DialogContext)
export const DialogProvider = ({ children }: DialogProviderProps) => {

   const [openSetores, setOpenSetores] = useState(false)
   const [openLayout, setOpenLayout] = useState(false)


   const fecharSetores = () => {
      setOpenSetores(false);
   }

   const abrirSetores = () => {
      setOpenSetores(true);
   }


   const fecharConfigLayoutSetores = () => {
      setOpenLayout(false);
   }

   const abrirConfigLayoutSetores = () => {
      setOpenLayout(true);
   }

   return (
      <DialogContext.Provider value={{
         fecharSetores,
         abrirSetores,
         fecharConfigLayoutSetores,
         abrirConfigLayoutSetores
      }}>
         <>
            {openSetores && (
               <DialogProcessoSetor openned={openSetores} />
            )}
            {openLayout && (
               <DialogConfigLayoutSetores openned={openLayout} />
            )}
            {children}
            
        

         </>
      </DialogContext.Provider>
   )

}