import { Divider, Grid } from '@material-ui/core';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { MenuPrincipalTop } from './components/menu-principal-top/menu-principal-top';
import { MenuPrincipalPerfil } from './components/menu-principal-perfil/menu-principal-perfil';
import { useStyles } from './menu-principal-styles';
import { ListModuloFilho } from './components/list-modulo-filho/list-modulo-filho';
import { MenuModel } from '../../../../../model/app';
import classNames from 'classnames';
import { useThemeQueries } from '../../../../theme';
import { VariaveisAmbiente } from '../../../../../config';
import { useGlobalStates } from '../../../../../services/app/providers/global-states-provider/global-states-provider';
import { useGlobalStateTools } from '../../../../../services/app/use-cases/global-states/global-state-tools';
import { useMenuPrincipal } from '../../../../../services/app/providers/menu-principal-provider';
import { useGetMenu } from '../../../../../data/app';
import { EnumMenu } from '../../../../../model';

export const MenuPrincipal = () => {
  const { fecharMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { menuGlobalState } = useGlobalStates();
  const { getValue } = useGlobalStateTools();
  const menuState = getValue(menuGlobalState!);
  const modeloAtual = menuState.modelo;
  const situacaoAtual = menuState.situacao;

  //CORRECAO DE MOBILE PARA O MENU
  const CorrecaoModeloAtual = isMobile ? 'Completo' : modeloAtual;

  const classes = useStyles();
  const { getMenu } = useGetMenu();
  const [modulosRetornados, setModulosRetornados] = useState([] as MenuModel[]);
  const refDrawer = useRef<HTMLDivElement>(null);

  const pinClicked = useCallback(() => {
    fecharMenu();
  }, [fecharMenu]);
  

  const leftAtual = situacaoAtual === 'Aberto'
    ? 0
    : modeloAtual === 'Completo'
      ? -350
      : -75;


  const itensMenu = React.useMemo(() => {
    return (
      <Grid className={classes.drawerOverflowMenu}>
        <Divider classes={{ root: classes.divider }} />
        {modulosRetornados
        .map((item) => (
          <ListModuloFilho
            key={item.codigo}
            modeloAtual={CorrecaoModeloAtual}
            codigoModulo={item.codigo}
            nomeModulo={item.nome}
            isFavorite={item.codigo === EnumMenu.MONITORAMENTO}
            rota={item.rota}
          />
        ))}
        <Grid className={classes.drawerVersion}>
          <div className={classes.publicPageHeaderVersionControl}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              {menuState.modelo === 'Completo' && (
                <div>
                  <b>Ambiente: </b>
                  <i>{VariaveisAmbiente.environment}</i>
                </div>
              )}
              <div>
                <b>Versão: </b>
                <i>{VariaveisAmbiente.version}</i>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }, [CorrecaoModeloAtual, classes.divider, classes.drawerOverflowMenu, classes.drawerVersion, classes.publicPageHeaderVersionControl, menuState.modelo, modulosRetornados]);

  useEffect(() => {
    const ret = getMenu(false, undefined);
    setModulosRetornados(ret);
  }, [getMenu]);

  const component = React.useMemo(() => {
    return (
      <>
        <div
          className={classNames(
            classes.drawerBackground,
            situacaoAtual === 'Aberto'
              ? classes.drawerBackgroundAberto
              : undefined,
          )}
          onClick={fecharMenu}
        ></div>
        <div
          className={classNames(
            classes.drawer,
            situacaoAtual === 'Aberto' ?
              classes.openDrawerMobile
              : undefined,
            modeloAtual === 'Mini' ? classes.drawerReduzido : undefined,
          )}
          style={{ left: leftAtual }}
        >
          {
            <Grid ref={refDrawer} className={classes.drawerContainer}>
              <Grid className={classes.drawerTop}>
                <MenuPrincipalTop
                  isMobile={isMobile}
                  modeloAtual={CorrecaoModeloAtual}
                  pinClicked={pinClicked}
                />
                <MenuPrincipalPerfil
                  isMobile={isMobile}
                  modeloAtual={CorrecaoModeloAtual}
                />
              </Grid>
              {itensMenu}
              <Grid className={classes.drawerBottom}>
                <Divider classes={{ root: classes.divider }} />
                <ListModuloFilho
                  key={EnumMenu.MASTER_SAIR}
                  isFavorite={false}
                  modeloAtual={CorrecaoModeloAtual}
                  codigoModulo={EnumMenu.MASTER_SAIR}
                  nomeModulo="Sair"
                  rota="/logout"
                />
              </Grid>
            </Grid>
          }
        </div>
      </>
    );
  }, [CorrecaoModeloAtual, classes.divider, classes.drawer, classes.drawerBackground, classes.drawerBackgroundAberto, classes.drawerBottom, classes.drawerContainer, classes.drawerReduzido, classes.drawerTop, classes.openDrawerMobile, fecharMenu, isMobile, itensMenu, leftAtual, modeloAtual, pinClicked, situacaoAtual]);

  return component;
};
