import { Button, Grid } from '@material-ui/core';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularLoading } from '../../../utils';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { ConfigLayoutFormModel } from '../../../../../model/app/forms/master/config-layout-model';
import { useFormConfigLayoutValidation } from './form-config-layout-validation';


export const FormConfigLayout = React.forwardRef<
  DefaultFormRefs<ConfigLayoutFormModel>,
  DefaultFormProps<ConfigLayoutFormModel>
>((props: DefaultFormProps<ConfigLayoutFormModel>, refs) => {

  const { FormConfigLayoutValidationYup } = useFormConfigLayoutValidation();
  const utilClasses = makeUtilClasses();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue
  } = useForm<ConfigLayoutFormModel>({
    resolver: yupResolver(FormConfigLayoutValidationYup),
    criteriaMode: 'all',
    mode: 'onSubmit',
  });

  const onSubmit = (form: ConfigLayoutFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (model: ConfigLayoutFormModel) => {
      reset({ ...model });
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {(props.loading && props.showLoading) ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : <></>}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${props.loading && utilClasses.controlLoading
            }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <Controller
                name="qtdSetores"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    fullWidth={true}
                    showStartAdornment={true}
                    autoComplete={'off'}
                    label="Qtde. de Setores"
                    placeholder="Máximo 4"
                    error={Boolean(
                      errors.qtdSetores && errors.qtdSetores.message,
                    )}
                    helperText={
                      touchedFields.qtdSetores || errors.qtdSetores
                        ? errors.qtdSetores?.message
                        : undefined
                    }
                    {...field}
                    onChange={(e)=> {
                      const valor = e.target.value
                      setValue('qtdSetores',valor)
                    }}
                    value={getValues('qtdSetores')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="qtdProcessos"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    fullWidth={true}
                    showStartAdornment={true}
                    autoComplete={'off'}
                    label="Qtde. de Processos"
                    placeholder="Máximo 4"
                    error={Boolean(
                      errors.qtdProcessos && errors.qtdProcessos.message,
                    )}
                    helperText={
                      touchedFields.qtdProcessos || errors.qtdProcessos
                        ? errors.qtdProcessos?.message
                        : undefined
                    }
                    {...field}
                    onChange={(e)=> {
                      const valor = e.target.value
                      setValue('qtdProcessos',valor)
                    }}
                    value={getValues('qtdProcessos')}
                  />
                )}
              />
            </Grid>
            
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
